export const iconAdd = <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
  <path
    d="M14.0007 25.6668C20.4173 25.6668 25.6673 20.4168 25.6673 14.0002C25.6673 7.5835 20.4173 2.3335 14.0007 2.3335C7.58398 2.3335 2.33398 7.5835 2.33398 14.0002C2.33398 20.4168 7.58398 25.6668 14.0007 25.6668Z"
    fill="white" stroke="#2F80ED" strokeWidth="1.5" strokeLinecap="round"
    strokeLinejoin="round"/>
  <path d="M9.33398 14H18.6673" stroke="#2F80ED" strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round"/>
  <path d="M14 18.6668V9.3335" stroke="#2F80ED" strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round"/>
</svg>