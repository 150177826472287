import Keycloak from 'keycloak-js'

// const envRealm = process.env.REACT_APP_KEYCLOAK_REALM
// const envURl = process.env.REACT_APP_KEYCLOAK_AUTH_URL
// const envClientID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID
const envRealm = process.env.REACT_APP_KEYCLOAK_REALM
const envURl = process.env.REACT_APP_KEYCLOAK_AUTH_URL
const envClientID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID

const init = {
	 "realm": envRealm,
	 "url": envURl,
	 "ssl-required": "external",
	 "public-client": true,
	 "flow": "standard",
	 "clientId": envClientID
}

export const keycloak = new Keycloak(init)

