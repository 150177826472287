import {useEffect, useState} from 'react'
import {iconUser} from '../../images/iconUserPhoto'
import {iconAddPhoto} from '../../images/iconAddPhoto'
import {NumberField} from './NumberField'

export const Modals = ({count, funcExit, submit}) => {

  return (
    <div className={'custom-modal'}>
      <div className={'custom-modal-content'}>
        <div>
          <h4>{`Выбрано: ${count}`}</h4>
          <p>Подтвердить перенос контактов?</p>
        </div>
        <div className={'custom-modal-footer'}>
          <button className={'btn btn-outline-secondary'} type={'button'}
                  onClick={() => funcExit(false)}>Отмена
          </button>
          <button className={'btn btn-primary'} type={'button'} onClick={submit}>Ок</button>
        </div>
      </div>

    </div>
  )
}

export const ModalParsing = () => {
  return (
    <div className={'custom-modal'}>
      <div className={'custom-modal-content'}>
        <div>
          <h4>Идет выгрузка контактов...</h4>
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>

    </div>
  )
}

export const ProgressBarModal = ({count, progress, funcExit, setFile}) => {
  const [progressPer, setProgressPer] = useState(0)

  useEffect(() => {
    setProgressPer(Math.ceil(progress / count * 100))
  }, [progress])


  return (
    <div className={'custom-modal'}>
      <div className={'custom-modal-content'}>
        <div>
          <h4>{`Перенесено ${progress} из ${count}`}</h4>
          <div className={'custom-progress-bar'}>
            <div style={{width: `${progressPer}%`}}></div>
          </div>
        </div>
        <div className={'custom-modal-footer'}>
          {
            progress === count &&
            <button className={'btn btn-primary'} type={'button'}
                    onClick={() => {

                      setFile(null)
                      funcExit(false)
                    }}>Ок</button>
          }
        </div>
      </div>
    </div>
  )
}

export const ModalWarning = ({currentElem, typeModal}) => {

  const textModal = {
    duplicate: {
      title: 'Повтор',
      message: 'Карточка контакта уже существует в базе, так как была перенесена ранее'
    },
    name: {
      title: 'Ошибка',
      message: 'Карточка контакта заполнена неверно, отсутствует имя'
    },
    number: {
      title: 'Ошибка',
      message: 'Карточка контакта заполнена неверно. Неверный № телефона'
    },
    name_number: {
      title: 'Ошибка',
      message: 'Карточка контакта заполнена неверно, отсутствует имя и неверный номер телефона'
    }

  }
  return (
    <div className={'custom-modal-warning'}
         style={currentElem < 5 ? {top: '-170px'} : {top: '35px'}}>
      <div className={'custom-modal-content'}>
        <div className={'d-flex flex-column align-items-center justify-content-center'}>

          <h4 className={'text-center mb-3'}>{textModal[typeModal].title}</h4>
          {/*<p className={'mb-0'}>Карточка контакта заполнена неверно.</p>*/}
          {/*<p className={'mb-0'}>Отсутствует имя/ неверный № телефона</p>*/}
          <p className={'mb-0'}>{textModal[typeModal].message}</p>
        </div>
        <div className={'custom-modal-footer'}>
          <h4 className={''}>Карточка Контакта не будет перенесена</h4>
        </div>
      </div>

    </div>
  )
}


export const ModalChangeContact = ({
                                     contact,
                                     setIsShowModalEdit,
                                     availableContacts,
                                     setContacts
                                   }) => {

  const {name, photo, telephones} = contact
  const [newPhoto, setNewPhoto] = useState(null)
  const [newFirstName, setNewFirstName] = useState('')
  const [newLastName, setNewLastName] = useState('')
  const [newMiddleName, setNewMiddleName] = useState('')
  const [newTelephones, setNewTelephones] = useState([])
  const [isDisabledBtn, setIsDisabledBtn] = useState(false)

  const disabledBtn = !(newFirstName || newLastName || newMiddleName)
  const isCountTel = newTelephones.length > 2

  useEffect(() => {
    setNewMiddleName(name?.middle_name)
    setNewLastName(name?.last_name)
    setNewFirstName(name?.first_name)
    setNewTelephones(telephones.length > 0 ? telephones : [{num: '', type: 'TYPE=CELL'}])
  }, [contact])

  const changeContact = () => {
    const newContact = {
      name: {
        last_name: newLastName,
        middle_name: newMiddleName,
        first_name: newFirstName
      },
      displayName: `${newLastName} ${newFirstName} ${newMiddleName}`,
      id: contact.id,
      photo: newPhoto || photo,
      telephones: newTelephones
    }

    setContacts((prevState) => prevState.map((item) => item.id === newContact.id ? newContact : item))
    setIsShowModalEdit(false)
  }

  return (
    <div className={'custom-modal'} onClick={() => setIsShowModalEdit(false)}>
      <div className={'custom-modal-content overflow-auto'} onClick={(e) => e.stopPropagation()}>
        <div className={'d-flex flex-column align-items-center'}>
          <div className={'w-100 d-flex justify-content-center align-items-center'}>

            <label>
              <div className={'icon-user position-relative'}>
                <span className={'position-absolute add-photo-icon'}>{iconAddPhoto}</span>
                {newPhoto ?
                  <img style={{width: '100%', height: '100%', borderRadius: '50%'}}
                       src={URL.createObjectURL(newPhoto[0])}
                       alt={''}/>
                  : photo ?
                    <img style={{width: '100%', height: '100%', borderRadius: '50%'}}
                         src={typeof photo === 'string' ? photo : URL.createObjectURL(photo[0])}
                         alt={''}/>
                    : iconUser}
              </div>
              <input className={'d-none'} type={'file'}
                     onChange={(event) => {
                       if (event.target.files[0]) {
                         setNewPhoto(event.target.files)
                       }
                     }}
                     accept={'image/jpeg,image/png,image/gif'}/>


            </label>

          </div>

          <div className={`w-100 d-flex flex-column align-items-start ${isCountTel ? 'mb-2' : 'mb-3'}`}>
            <label>ФАМИЛИЯ</label>
            <input
              value={newLastName}
              onChange={(event) => setNewLastName(event.target.value)}
            />
          </div>
          <div className={`w-100 d-flex flex-column align-items-start ${isCountTel ? 'mb-2' : 'mb-3'}`}>
            <label>ИМЯ</label>
            <input value={newFirstName}
                   onChange={(event) => setNewFirstName(event.target.value)}
            />
          </div>
          <div className={'w-100 d-flex flex-column align-items-start'}>
            <label>ОТЧЕСТВО</label>
            <input value={newMiddleName}
                   onChange={(event) => setNewMiddleName(event.target.value)}

            />
            <p
              className={'align-self-end warning-text mw-100 pb-0'}>{disabledBtn ? 'Заполните хотя бы одно из полей: Фамилия, Имя, Отчество' : ''}</p>
          </div>
          <div className={'w-100 d-flex flex-column align-items-start'}>
            {newTelephones.map((item, index) => {
              return (
                <NumberField key={item.id} index={index} tel={item}
                             availableContacts={availableContacts}
                             isCountTel={isCountTel}
                             setIsDisabledBtn={setIsDisabledBtn}
                             setNewTelephones={setNewTelephones}/>
              )
            })}
          </div>
        </div>

        <div className={'custom-modal-footer'}>
          <button className={'btn btn-outline-secondary'}
                  onClick={() => setIsShowModalEdit(false)}>Отмена
          </button>
          <button className={'btn btn-primary'}
                  disabled={isDisabledBtn || disabledBtn}
                  onClick={changeContact}

                  type={'button'}>Ок
          </button>
        </div>
      </div>

    </div>
  )
}


export const ModalError = ({setFile, setPage, getContactsAll, page, setIsDownloadContacts}) => {

const repeatDownloadContacts = () => {
  setIsDownloadContacts(false)
  page === 1 ? getContactsAll() : setPage(1)
}
  return (
    <div className={'custom-modal'}>
      <div className={'custom-modal-content'}>
        <div className={'d-flex flex-column align-items-center justify-content-center'}>

          <h4 className={'text-center mb-4 mt-3'}>Ошибка выгрузки контактов</h4>
          <p className={'mb-0'}>Проверьте подключение к интернету и повторите попытку</p>
          {/*<p className={'mb-0'}>Отсутствует имя/ неверный № телефона</p>*/}

        </div>
        <div className={'custom-modal-footer'}>

          <button className={'btn btn-outline-secondary'} onClick={() => setFile(null)}>Отмена
          </button>
          <button className={'btn btn-primary'}
            onClick={repeatDownloadContacts}

                  type={'button'}>Повторить
          </button>

        </div>
      </div>

    </div>
  )
}

