export const iconWarningDuplicate = <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
  <path
    d="M7.71758 2.89491L1.36508 13.4999C1.2341 13.7267 1.1648 13.9839 1.16407 14.2458C1.16334 14.5077 1.23119 14.7653 1.3609 14.9928C1.4906 15.2204 1.67762 15.41 1.90336 15.5428C2.12909 15.6756 2.38568 15.747 2.64758 15.7499H15.3526C15.6145 15.747 15.8711 15.6756 16.0968 15.5428C16.3225 15.41 16.5096 15.2204 16.6393 14.9928C16.769 14.7653 16.8368 14.5077 16.8361 14.2458C16.8354 13.9839 16.7661 13.7267 16.6351 13.4999L10.2826 2.89491C10.1489 2.67449 9.96062 2.49225 9.73597 2.36577C9.51133 2.2393 9.25788 2.17285 9.00008 2.17285C8.74228 2.17285 8.48882 2.2393 8.26418 2.36577C8.03954 2.49225 7.85128 2.67449 7.71758 2.89491V2.89491Z"
    stroke="#DD791D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M9 6.75V9.75" stroke="#DD791D" strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round"/>
  <path d="M9 12.75H9.0075" stroke="#DD791D" strokeWidth="1.5" strokeLinecap="round"
        strokeLinejoin="round"/>
</svg>
