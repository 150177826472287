import {iconUser} from '../../../images/iconUserPhoto'
import {iconWarning} from '../../../images/iconWarning'
import {ModalChangeContact, ModalWarning} from '../../modal/Modals'
import {checkValidContact} from '../../../functions/helpers'
import {iconWarningDuplicate} from '../../../images/iconWarningDuplicate'
import {iconEdit} from '../../../images/iconEdit'
import {useState} from 'react'

const checkStyleContact = (string) => {
  if (string === 'duplicate') {
    return '#F9EEE4'
  }
  if (string === 'number' || string === 'name' || string === 'name_number') return 'rgba(213, 0, 0, 0.1)'
  return 'inherit'
}

export const ContactRow = ({
                             contact,
                             setImportedContacts,
                             importedContacts,
                             countContactsAll,
                             currentIndex,
                             availableContacts,
                             setContacts
                           }) => {

  const {photo, telephones, name, id} = contact
  const [isShowModalEdit, setIsShowModalEdit] = useState(false)


  const validContact = checkValidContact(contact, availableContacts)

  const style = {
    backgroundColor: checkStyleContact(validContact)
  }

  return (
    <>
      {isShowModalEdit &&
        <ModalChangeContact contact={contact} setIsShowModalEdit={setIsShowModalEdit} setContacts={setContacts}
                            availableContacts={availableContacts}/>}
      <tr className={'px-3'}
          style={style}>
        <td>
          <div className={'checkbox'}>
            <input type={'checkbox'} id={'checkbox' + id}
                   checked={importedContacts.some((_item) => {

                     return _item.id === id
                   })}
              // defaultChecked={!isCheckValidContact(item)}
                   disabled={validContact !== 'ok'}
                   onChange={(event) => {
                     event.target.checked === true
                       ? setImportedContacts([...importedContacts, contact])
                       : setImportedContacts(importedContacts.filter((_item) => _item.id !== id))
                   }}/>
            <label htmlFor={'checkbox' + id} className={'checkbox_label'}></label>
          </div>

        </td>
        <td>
          <div className={'icon-user my-1'}>{photo ?
            <img style={{height: '100%', width: '100%',  borderRadius: '50%'}} src={typeof (photo[0]) === 'string' ? photo : URL.createObjectURL(photo[0])}
                 alt={''}/> : iconUser}</div>
        </td>
        <td>{name ? `${name.last_name || ''} ${name?.first_name || ''} ${name?.middle_name || ''}` : ''}
        </td>
        <td
        >
          <div className={'d-flex justify-content-center flex-column p-1'} style={{gap: '1px'}}>
            {telephones?.map((item) =>
              <span key={item.id} className={'p-1'}>{item.num}</span>)}
          </div>

        </td>
        <td className={'position-relative'}>
          <div className={'d-flex justify-content-end align-items-center'}>
            {validContact === 'ok' ? ''
              : <div className={'icon-warning'}>
                <span
                  className={'mr-4'}>{validContact === 'duplicate' ? iconWarningDuplicate : iconWarning}</span>
                <ModalWarning currentElem={countContactsAll - currentIndex}
                              typeModal={validContact}/>
              </div>}
            <span onClick={() => setIsShowModalEdit(true)}
                  className={'mr-3 icon-edit'}>{iconEdit}</span>
          </div>
        </td>
      </tr>
    </>
  )
}