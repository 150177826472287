import axios from 'axios'

const base_url = process.env.REACT_APP_API_DOMAIN

export const createContact = (data, token) => axios.post(`${base_url}/api/v1/users/current/contacts/`, data, {
  headers: {Authorization: `Bearer ${token}`}
})

export const createPhotoContact = (uuid, data, token) => axios.put(`${base_url}/api/v1/users/current/contacts/${uuid}/photo/`, data, {
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'multipart/form-data'
  }
})

export const getAdminInfo = (token) => axios(base_url + '/api/v1/admins/current/', {
  headers: {Authorization: `Bearer ${token}`}
})

export const getContacts = (page, token) => axios(base_url + '/api/v2/users/current/phone-book/', {
  params: {contact_type: 1, page_size: 50, page},
  headers: {Authorization: `Bearer ${token}`}
})