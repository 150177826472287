const checkDuplicate = (contact, arr = []) => {
  return arr.some((item) => {
    return item.phone_numbers.some((_item) => {
      return contact.telephones.some((__item) => {
        return _item.phone_number === __item.num
      })
    })
  })
}


export const checkValidContact = (contact, arr) => {
  if (checkDuplicate(contact, arr)) return 'duplicate'

  let validName = false
  let validNumber = false
  if (contact.name) {
    const {first_name, last_name, middle_name} = contact.name
    if (first_name || last_name || middle_name) {
      validName = true
    }
  }

  if (contact.telephones.length > 0) {
    contact.telephones.forEach((item, index) => {
      if (index > 0 && validNumber === false) {

      } else {
        if (item.num.length > 2 && item.num.length < 20) {
          if ((/^\d+$/g.test(item.num.slice(1)) && (item.num[0] === '+' || item.num[0] === '8'))) {
            validNumber = true
          }
          if (item.num.length < 6) {
            validNumber = true
          }
        }
      }

    })
  }

  if (validName && validNumber) return 'ok'
  if (!validName && !validNumber) return 'name_number'
  if (!validNumber) return 'number'
  if (!validName) return 'name'
}


export const createContactData = (data) => {
  const {name, telephones} = data
  return {
    type: 1,
    phone_numbers: telephones.map(item => {
      let type = 1
      if (item.type?.toLowerCase() === 'home') type = 3
      if (item.type?.toLowerCase() === 'work') type = 2
      return {type: type, phone_number: item.num.replace(/[\s.,()-]/g, '')}
    }),
    first_name: (name.first_name || name.last_name || name.middle_name),
    last_name: name.first_name ? name.last_name || '' : '',
    middle_name: name.first_name ? (name.middle_name || '') : (name.last_name ? name.middle_name : ''),
    emails: []
  }
}


export const checkPhoneNumber = (type) => {
  if (type.includes('WORK')) return 'Рабочий'
  if (type.includes('HOME')) return 'Домашний'
  return 'Мобильный'

}


export const filteredUniquePhones = (arr) => {
  const arrUnique = []
  arr.forEach((item) => {
      if (arrUnique.length === 0) return arrUnique.push(item)
      if (arrUnique.some((_item) => _item.num !== item.num)) return arrUnique.push(item)
    }
  )
  return arrUnique
}
