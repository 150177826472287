import {iconSetting} from '../../images/iconSetting'

export const Instruction = () => {
  return (
    <>
      <p className={'card-title'}>ИНСТРУКЦИЯ ДЛЯ ПЕРЕНОСА КОНТАКТОВ</p>
      <div className={'main'}>
        <div className={'main-direction'}>
          <div className={'main-direction-header'}>
              <span>
                IOS
              </span>
          </div>
          <div className={'main-direction-body mt-3'}>
            <h5 className={'mb-3'}>Инструкция для переноса контактов с iPhone на компьютер через
              iCloud</h5>
            <p>Для того, чтобы скопировать контакты с айфона на компьютер через iCloud, вам
              потребуется подключение к Интернету. Выполните следующие действия для синхронизации
              контактов с iCloud:</p>
            <ul>
              <li>Откройте на вашем iPhone приложение <strong>Настройки –
                iCloud</strong> (на <strong>iOS 11 Настройки –
                Учетные записи и пароли – iCloud</strong>). Далее поставьте ползунок в активное
                состояние
                напротив пункта Контакты.
              </li>
              <li>Теперь на вашем компьютере откройте <strong>веб-браузер</strong> и зайдите на
                сайт <strong>iCloud.com.</strong>
                Введите <strong>Apple ID и пароль</strong>, пройдите <strong>авторизацию</strong>.
              </li>
              <li>Далее в главном окне нажмите на иконку «<strong>Контакты</strong>». Вы увидите
                список всех ваших
                контактов.
              </li>
              <li>Нажмите на любой контакт, подсветив его, затем нажмите
                комбинацию <strong>Ctrl+A</strong>, чтобы
                выделить все контакты в телефонной книге. Затем в левом нижнем углу нажмите на
                кнопку {iconSetting}, чтобы попасть в <strong>Настройки</strong>.
              </li>
              <li>Выберите пункт «<strong>Экспорт Card</strong>». После этого список всех ваших
                контактов будет
                перенесен в Windows Contact, и вы сможете просматривать их на компьютере.
              </li>
              <li>Полученный файл выбираете для дальнейшей загрузки по кнопке «<strong>Загрузить
                Контакты</strong>».
              </li>
            </ul>
          </div>
        </div>

        <div className={'main-direction'}>
          <div className={'main-direction-header'}>
              <span>
                Android
              </span>
          </div>
          <div>
            {/*<h4>Инструкция для переноса контактов с iPhone на компьютер через iCloud</h4>*/}
            {/*<p>Откройте приложение «Контакты»</p>*/}
            <ul className={'ul-right mt-3'}>
              <li>Откройте приложение <strong>«Контакты»</strong>
              </li>
              <li>Нажмите по кнопке <strong>«Меню»</strong> и выбираете <strong>«Управление
                Контактами»</strong> и выберите пункт
                <strong>«Экспорт»</strong>
              </li>
              <li>Выбираете в категории <strong>«Импорт/Эскпорт
                Контактов»</strong> кнопку <strong>«Экспорт»</strong>
              </li>
              <li>В следующем окне устанавливаете переключатель на <strong>«Память
                устройства»</strong> и ниже
                выбираете кнопку <strong>«Экспорт»</strong>
              </li>
              <li>Экспорт на накопитель — все контакты сохраняются в файл <strong>vcf</strong> на
                устройстве, после
                этого вы можете его передать на компьютер любым удобным способом, например,
                подключив телефон к компьютеру по <strong>USB</strong>
              </li>
              <li>В результате вы получаете файл <strong>«Контакты.vcf»</strong> с сохраненными
                контактами на
                компьютере, который выбираете для дальнейшей загрузки по кнопке <strong>«Загрузить
                  Контакты».</strong>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </>
  )
}