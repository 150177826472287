import {useContext, useState} from 'react'
import {KeycloakContext} from './auth/keycloakProvider'
import {App} from '../App'
import {Auth} from './auth/Auth'
import {PageWelcome} from './pageWelcome/PageWelcome'

export const AppRouter = () => {
  const [isPageWelcome, setIsPageWelcome] = useState(true)
  const {logged} = useContext(KeycloakContext)

  return (logged ?
      isPageWelcome ? <PageWelcome setIsPageWelcome={setIsPageWelcome}/> :
        <App setIsPageWelcome={setIsPageWelcome}/>
      : <Auth/>
  )
}