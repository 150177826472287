import './App.css'
import {useEffect, useState} from 'react'
import {Header} from './components/header/Header'
import {iconAdd} from './images/iconAdd'
import {Instruction} from './components/instruction/Instruction'
import {TableContacts} from './components/tableContacts/tableContacts'
import {parseVCF} from './functions/parser'


export const App = ({setIsPageWelcome}) => {
  const [file, setFile] = useState(null)
  const [contacts, setContacts] = useState(null)


  useEffect(() => {
    if (file) parseFile(file)
  }, [file])


  const parseFile = (file) => {

    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => {
      // console.log(reader.result)
      // console.log(vCardParser.parse(reader.result))
      setContacts(parseVCF(reader.result))

    }
  }
  return (
    <div className="App">
      <Header setIsPageWelcome={setIsPageWelcome}/>
      {contacts && file ?
        <TableContacts data={contacts} setFile={setFile}/>
        :
        <div className={'wrapper card'}>
          <p className={'card-title'}>ВЫБЕРИТЕ ФАЙЛ ДЛЯ ПЕРЕНОСА ВАШИХ КОНТАКТОВ</p>
          <label className={'mb-5'}>
            <div className={'input'}>
              {iconAdd}
              <p>Загрузите Контакты</p>
            </div>
            <input className={'input-none'} type={'file'} accept={'.vcf'} onChange={(event) => {
              setFile(event.target.files[0])
            }}/>
          </label>
          <Instruction/>
        </div>
      }
    </div>
  )
}