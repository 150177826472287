import {ReactKeycloakProvider} from '@react-keycloak/web'
import {createContext, useState} from 'react';
import {keycloak} from './kcInit';

export const KeycloakProvider = ({children}) => {
  const [onLoad, setOnLoad] = useState('check-sso')
  // const [onLoad, setOnLoad] = useState('login-required')
  const [kcEvent, setKcEvent] = useState(null)
  const [logoutMSG, setLogoutMSG] = useState(() =>
    sessionStorage.getItem('kcError') || null)

  const kcEventLogger = (event, error) => {
    setKcEvent({event, error})

    if (error) appLogoutWithMsg(error)
  }
  // const tokenLogger = (tokens) => {
  // 	console.log('onKeycloakTokens', tokens)
  // }

  const appLogoutWithMsg = (msg) => {
    setOnLoad('check-sso')
    sessionStorage.setItem('kcError', msg)
    msg && setLogoutMSG(msg)
    keycloak.logout()
  }

  const initKC = () => {
    sessionStorage.removeItem('kcError')
    setOnLoad('login-required')
  }

  // const isKcLogged = useMemo(() => keycloak.hasRealmRole('smass-client'),
  //   [keycloak.authenticated, keycloak.token])

  const logged = keycloak.authenticated

  // useEffect(() => {
  //   if (keycloak.authenticated) {
  //     !isKcLogged && appLogoutWithMsg('Доступно только администраторам')
  //   }
  // }, [keycloak.authenticated, keycloak.token])



  return (
    <ReactKeycloakProvider
      initOptions={{onLoad, checkLoginIframe: false}}
      // initOptions={{onLoad, checkLoginIframe: true}}
      authClient={keycloak}
      onEvent={kcEventLogger}
      // onTokens={tokenLogger}
      // LoadingComponent={!kcEvent?.error && <LoaderKcPage/>}
      LoadingComponent={!kcEvent?.error && <div/>}
    >
      <KeycloakContext.Provider
        value={{appLogoutWithMsg, logged, initKC, kcEvent, logoutMSG}}
      >
        {children}
      </KeycloakContext.Provider>
    </ReactKeycloakProvider>

  )
}

export const KeycloakContext = createContext({})
