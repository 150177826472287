export const iconWarning = <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
  <path
    d="M9 0C4.02528 0 0 4.02564 0 9C0 13.9747 4.02564 18 9 18C13.9747 18 18 13.9744 18 9C18 4.02528 13.9743 0 9 0ZM9 16.5938C4.80259 16.5938 1.40625 13.1971 1.40625 9C1.40625 4.80259 4.80287 1.40625 9 1.40625C13.1974 1.40625 16.5938 4.80287 16.5938 9C16.5938 13.1974 13.1971 16.5938 9 16.5938Z"
    fill="#FB4343"/>
  <path
    d="M9 7.53516C8.61166 7.53516 8.29688 7.84995 8.29688 8.23828V12.7662C8.29688 13.1545 8.61166 13.4693 9 13.4693C9.38834 13.4693 9.70312 13.1545 9.70312 12.7661V8.23828C9.70312 7.84995 9.38834 7.53516 9 7.53516Z"
    fill="#FB4343"/>
  <path
    d="M9 6.67432C9.52424 6.67432 9.94922 6.24934 9.94922 5.7251C9.94922 5.20086 9.52424 4.77588 9 4.77588C8.47576 4.77588 8.05078 5.20086 8.05078 5.7251C8.05078 6.24934 8.47576 6.67432 9 6.67432Z"
    fill="#FB4343"/>
</svg>