import {LogoEFKO} from '../../images/LogoEFKO'

export const PageWelcome = ({setIsPageWelcome}) => {
  return (
    <div className={'d-flex justify-content-center align-items-center bg-transparent vh-100'}>
      <div className={'page-welcome'}>
        <div className={'text-center mb-5'}><LogoEFKO width={200}/></div>
        <h2 className={'text-center'}>Добро Пожаловать!</h2>
        <p className={'text-center mb-0'}>Вас приветствует сервис для переноса Вашей</p>
        <p className={'text-center mb-0'}>телефонной книги на рабочий телефон</p>
        <div className={'d-flex justify-content-center'}>
          <button className={'btn btn-primary'} onClick={() => setIsPageWelcome(false)}>Продолжить</button>
        </div>

      </div>
    </div>
  )
}