import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import {KeycloakProvider} from './components/auth/keycloakProvider'
import {AppRouter} from './components/AppRouter'
import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <KeycloakProvider>
    <React.StrictMode>
      <AppRouter/>
    </React.StrictMode>
  </KeycloakProvider>
)
// ReactDOM.render(
//   <React.StrictMode>
//     <KeycloakProvider>
//
//         <AppRouter/>
//
//     </KeycloakProvider>
//   </React.StrictMode>
//   ,document.getElementById('root')
// )
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
