import {filteredUniquePhones} from './helpers'


export const parseVCF = (stringVCF) => {
  let contacts = []
  const result = []
  const arr = stringVCF.split('BEGIN:VCARD')
  arr.map((item) => {
    return contacts.push(
      item.replace(/\n\s{1}/g, '')
        // split if a character is directly after a newline
        .split(/\r\n(?=\S)|\r(?=\S)|\n(?=\S)/)
    )
  })
  contacts = contacts.map((item) => item.slice(2, -1))

  contacts.map((item, index) => {
    let photo
    let telephones = []
    let name
    // let fn
    item.map((_item, _index) => {
      if (_item.indexOf('PHOTO') === 0) {
        photo = item.slice(_index).join('')

        const typePhoto = photo.split(':')[0].split(';')
        let strImage = photo.split(':')[1]
        if (strImage.includes('X-PHOTOSTATE')) {
          strImage = strImage.slice(0, strImage.indexOf('X-PHOTOSTATE'))
        }

        photo = `data:image/${typePhoto[typePhoto.length - 1].toLowerCase()};base64,${strImage}`
      }
      if (_item.indexOf('TEL') === 0 || _item.indexOf('item1.TEL') === 0) {

        _item.split(';').map((item) => {

          if (item.includes(':')) {
            // console.log(_item.split(';')[1])
            telephones.push({
              num: item.split(':')[1].replace(/-/g, '').replace(/\s/g, '').replace('(', '').replace(')', ''),
              type: _item.split(';')[1]
            })

          }
        })

      }

      if (_item.indexOf('N') === 0 && (_item[1] === ';' || _item[1] === ':')) {
        if (_item.includes('ENCODING=QUOTED-PRINTABLE')) {
          name = _item.replace(/={1}/g, '%')
          if (item[_index + 1][0] === '=') {
            name = name + item[_index + 1].replace(/={1}/g, '%').slice(1)
          }
          if (name[name.length - 1] === '%') {
            name = name.substring(0, name.length - 1)
          }
          name = name.split(':')[1].split(';')
          name = name.map((item) => {
            try {
              return decodeURI(item)
            } catch (e) {

              return null
            }

          })

        } else {
          name = _item.split(':')[1].split(';')
        }
      }
    })


    if (telephones.length > 1 || name || name?.some((item) => item) || photo) return result.push({
      photo,
      telephones: filteredUniquePhones(telephones),
      name: name ? {last_name: name[0], first_name: name[1], middle_name: name[2]} : null,
      displayName: name ? `${name[0]} ${name[1]} ${name[2]}` : null
    })

  })

  return result
}