import React from 'react'

const LogoEfkoSVG = ({...props}) => (
  <svg width="438" viewBox="0 0 438 132" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M429.977 49.2402H428.447H395.597H377.107C373.757 49.1502 365.197 51.5802 362.837 60.5002L355.937 86.1802L354.187 92.7102C353.157 96.3802 354.087 99.1902 355.937 101.03C357.657 102.74 360.177 103.64 362.677 103.64L395.597 103.74L414.067 103.8C418.747 103.8 426.827 99.8102 428.397 92.5502L437.097 60.4102C438.857 53.9002 436.527 49.2402 429.977 49.2402ZM412.477 62.2502L404.867 90.5602C404.027 93.9402 400.957 96.4502 397.457 96.3602L395.597 96.3502L383.097 96.3102C379.437 96.3102 377.877 93.8902 378.697 90.8602L386.217 62.5402C386.817 59.8702 389.517 56.6702 393.657 56.7202H395.597L408.137 56.7002C410.057 56.6302 413.617 58.2902 412.477 62.2502Z"
      fill="#096000"/>
    <path
      d="M274.937 60.4005C275.907 56.8205 275.277 53.8005 273.417 51.8105C271.907 50.1905 269.577 49.2505 266.647 49.2505H266.287H235.437H216.347L216.327 49.3205L191.417 49.2705L174.917 49.2505C170.247 49.2505 162.157 53.2405 160.597 60.5005L151.957 92.3805L151.887 92.6505C151.137 95.4305 151.167 97.8905 151.957 99.7805C153.017 102.29 155.427 103.811 159.157 103.811H160.537H191.407H193.737L192.087 109.85H216.277L217.937 103.76L235.427 103.79L251.897 103.82C256.577 103.82 264.667 99.8305 266.227 92.5705L273.407 66.0605L274.937 60.4005ZM195.797 96.3105L191.427 96.3205H180.787C178.867 96.3805 175.307 94.7205 176.457 90.7705L184.057 62.4405C184.897 59.0805 187.947 56.5705 191.427 56.6505H191.457L206.627 56.6605L195.797 96.3105ZM250.687 62.2505L243.067 90.5605C242.237 93.9405 239.167 96.4505 235.667 96.3605H235.437L219.967 96.3405L230.797 56.7005H235.437L246.357 56.6905C248.277 56.6305 251.827 58.2905 250.687 62.2505Z"
      fill="#096000"/>
    <path
      d="M152.157 60.8401C152.797 58.4501 152.697 56.3201 151.967 54.5701C150.717 51.5501 147.647 49.6801 143.507 49.6801C121.977 49.6801 104.047 49.5801 82.367 49.5801L80.317 57.0301H123.747C125.667 56.9701 129.237 58.7301 128.077 62.6901L125.277 73.1001H86.037L84.007 80.5501H123.277L120.467 90.9901C119.817 93.6201 117.827 95.7201 115.327 96.4801C114.587 96.6201 113.817 96.6901 113.027 96.6901H69.507L67.417 104.14L129.127 104.21C133.787 104.21 141.877 100.22 143.447 92.9601L151.957 61.5301L152.157 60.8401Z"
      fill="#096000"/>
    <path
      d="M355.947 50.5703L358.117 49.3203H355.947H342.147L304.667 70.6403C306.527 63.8303 307.967 58.4803 308.147 57.7603C309.157 53.3603 308.017 49.0903 298.587 49.0903C296.987 49.0903 286.317 49.0703 286.317 49.0703L273.417 96.6703L271.477 103.83H273.417H295.617C295.617 103.83 298.547 93.1103 301.657 81.7103L310.537 76.8003L325.887 103.87H351.647L329.957 65.6003L355.947 50.5703Z"
      fill="#096000"/>
    <path d="M214.177 120.61L213.877 122.26H210.307L208.617 131.16H206.627L208.617 120.61H214.177Z" fill="#096000"/>
    <path
      d="M221.977 127.44H220.817L220.107 131.16H218.087L220.137 120.61H222.167H223.167C223.647 120.61 224.087 120.64 224.497 120.73C225.747 121 226.537 121.79 226.537 123.16C226.517 125.67 224.757 127.44 221.977 127.44ZM223.577 122.29C223.347 122.21 223.067 122.18 222.777 122.18H221.837L221.127 125.87H222.277C223.627 125.87 224.367 124.75 224.367 123.47C224.357 122.88 224.117 122.45 223.577 122.29Z"
      fill="#096000"/>
    <path
      d="M240.937 120.61C239.657 123.1 238.277 125.55 236.657 128.11C235.067 130.68 234.357 131.34 232.607 131.34C232.197 131.34 231.717 131.28 231.327 131.19L231.617 129.63H232.617C233.407 129.63 233.737 129.4 234.297 128.5C233.477 126.08 232.787 123.42 232.207 120.61H234.337C234.657 122.47 235.097 124.45 235.587 126.08L235.667 126.52H235.687L235.917 126.08C236.897 124.4 237.927 122.45 238.817 120.61H240.937Z"
      fill="#096000"/>
    <path
      d="M250.737 131.16L252.417 122.35H248.317L246.657 131.16H244.667L246.657 120.61H254.727L252.717 131.16H250.737Z"
      fill="#096000"/>
    <path
      d="M265.667 131.16L267.347 122.35H263.257L261.597 131.16H259.607L261.597 120.61H269.667L267.657 131.16H265.667Z"
      fill="#096000"/>
    <path
      d="M281.067 131.16C281.007 130.39 280.917 129.51 280.827 128.7H276.837C276.417 129.53 276.017 130.38 275.657 131.16H273.637C275.407 127.53 277.237 124.1 279.277 120.61H281.607C282.317 124.15 282.877 127.73 283.227 131.16H281.067ZM280.697 127.14C280.547 126.11 280.377 125.04 280.217 123.98C280.157 123.51 280.097 123.03 280.037 122.54H280.017C279.747 123.04 279.487 123.55 279.227 124.02C278.667 125.05 278.107 126.11 277.567 127.13H280.697V127.14Z"
      fill="#096000"/>
    <path
      d="M303.457 131.16L301.467 126.2L300.577 127.03L299.787 131.16H297.787L299.817 120.61H301.797L301.007 124.75C302.427 123.39 303.837 121.98 305.257 120.61H307.777L303.097 124.87L305.737 131.16H303.457Z"
      fill="#096000"/>
    <path
      d="M316.297 131.37C313.487 131.37 312.047 130.06 312.047 127.5C312.047 124.48 313.637 120.4 318.167 120.4C320.937 120.4 322.417 121.78 322.417 124.27C322.417 127.27 320.937 131.37 316.297 131.37ZM317.897 122.08C315.157 122.08 314.197 125.18 314.197 127.34C314.197 128.76 314.797 129.7 316.567 129.7C318.997 129.7 320.267 127.1 320.267 124.49C320.267 122.98 319.657 122.08 317.897 122.08Z"
      fill="#096000"/>
    <path
      d="M337.377 131.16C337.907 128.27 338.357 125.34 338.567 122.74H338.547L334.267 131.16H332.377L331.427 122.74H331.397C330.657 125.31 329.917 128.29 329.337 131.16H327.497C328.177 127.81 329.157 124.07 330.247 120.61H333.107L333.967 128.17H333.987L337.807 120.61H340.757C340.467 124.1 339.997 127.71 339.367 131.16H337.377Z"
      fill="#096000"/>
    <path
      d="M352.177 131.16L353.857 122.35H349.757L348.097 131.16H346.107L348.097 120.61H356.167L354.157 131.16H352.177Z"
      fill="#096000"/>
    <path
      d="M367.576 131.16C367.516 130.39 367.426 129.51 367.336 128.7H363.346C362.926 129.53 362.526 130.38 362.166 131.16H360.146C361.916 127.53 363.746 124.1 365.786 120.61H368.116C368.826 124.15 369.386 127.73 369.736 131.16H367.576ZM367.216 127.14C367.066 126.11 366.896 125.04 366.736 123.98C366.676 123.51 366.616 123.03 366.556 122.54H366.536C366.266 123.04 366.006 123.55 365.746 124.02C365.186 125.05 364.626 126.11 364.086 127.13H367.216V127.14Z"
      fill="#096000"/>
    <path
      d="M383.727 131.16H381.737L382.617 126.57H378.587L377.707 131.16H375.687L377.737 120.61H379.767L378.937 124.83H382.967L383.797 120.61H385.786L383.727 131.16Z"
      fill="#096000"/>
    <path
      d="M396.967 131.16L398.037 125.49L398.357 123.98L397.147 125.46L392.337 131.16H390.597L392.587 120.61H394.547L393.457 126.28L393.137 127.79L394.347 126.31L399.167 120.61H400.887L398.897 131.16H396.967Z"
      fill="#096000"/>
    <path
      d="M412.137 131.16L413.207 125.49L413.527 123.98L412.317 125.46L407.507 131.16H405.767L407.757 120.61H409.717L408.627 126.28L408.307 127.79L409.517 126.31L414.337 120.61H416.057L414.067 131.16H412.137ZM415.367 117.51C415.157 118.98 413.977 120.05 412.087 120.05C410.487 120.05 409.637 119.29 409.637 118.15C409.637 117.94 409.647 117.73 409.717 117.52H411.347C411.177 118.34 411.437 118.8 412.267 118.8C413.037 118.8 413.537 118.33 413.677 117.52H415.367V117.51Z"
      fill="#096000"/>
    <path
      d="M5.44648 89.7299C13.2965 110.74 31.0965 125.1 51.4265 129.39C51.6565 129.44 51.8865 129.48 52.1065 129.53C52.1065 129.53 52.6665 129.53 52.8365 128.96C53.1065 128.04 52.4565 127.85 52.4565 127.85C33.4765 123.25 18.9265 110.75 11.6765 91.3298C9.78648 86.2598 8.59648 81.1499 8.05648 76.1099C5.10648 72.5199 2.91648 68.9699 1.46648 65.6099C1.21648 73.5999 2.47648 81.7699 5.44648 89.7299Z"
      fill="#096000"/>
    <path
      d="M1.86621 60.36C3.34621 63.2 5.32622 66.0499 7.74622 68.8499C7.83622 63.7199 8.59622 58.7 9.99622 53.89C8.05622 51.34 6.44621 48.7699 5.20621 46.1899C3.55621 50.7499 2.42621 55.51 1.86621 60.36Z"
      fill="#096000"/>
    <path
      d="M11.2969 33.7701C12.6269 35.8801 14.2669 37.9902 16.1769 40.0702C17.9769 37.1602 20.0369 34.3902 22.3669 31.8102C20.5169 29.5901 19.0269 27.3602 17.9169 25.1602C15.4669 27.8502 13.2369 30.7301 11.2969 33.7701Z"
      fill="#096000"/>
    <path
      d="M111.937 27.8801C95.3865 7.84006 67.4965 -0.569941 41.9365 8.97006C35.0965 11.5301 28.9765 15.1701 23.6865 19.6301C24.9665 21.6901 26.6565 23.7501 28.6965 25.7801C33.9465 21.4701 40.0765 17.9001 47.0165 15.3101C70.7065 6.46006 93.9265 11.8801 110.367 28.5101C110.367 28.5101 111.177 29.5501 111.957 29.1601C112.497 28.9001 111.937 27.8801 111.937 27.8801Z"
      fill="#096000"/>
    <path
      d="M4.79631 34.26C5.36631 53.35 27.3963 71.99 54.9063 82.88C55.7963 83.23 55.1763 83 56.0763 83.34L69.4363 67.77C36.2763 58.75 6.19631 35.7 10.1863 18C7.66631 23.13 4.58631 27.38 4.79631 34.26Z"
      fill="#096000"/>
    <path
      d="M25.8561 0C24.1961 1.1 22.2161 2.53 20.6861 4.56C17.9061 7.96 16.6761 12.61 17.6661 17.61C20.2061 30.39 36.4161 44.72 60.0661 54.35L68.3561 45.33C37.6561 35.88 12.7261 13.06 25.8561 0Z"
      fill="#A3A3A3"/>
    <path
      d="M40.5262 96.6601C42.2662 94.5901 45.6962 90.7001 47.8662 88.1301C24.8162 80.9601 -3.41377 55.6601 1.68623 42.8501C-6.03377 62.2801 13.7962 83.9701 39.9262 96.3701L40.5262 96.6601Z"
      fill="#A3A3A3"/>
  </svg>

);

export const LogoEFKO = React.memo(LogoEfkoSVG, () => true);