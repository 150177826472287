import {useEffect, useState} from 'react'
import {ModalError, ModalParsing, Modals, ProgressBarModal} from '../modal/Modals'
import {createContact, createPhotoContact, getContacts} from '../../api/api'
import {useKeycloak} from '@react-keycloak/web'
import {ContactRow} from './contactRow/ContactRow'
import {checkValidContact, createContactData} from '../../functions/helpers'


const searchContacts = (arr, value) => {
  return arr.filter((item) => {
    return item.displayName.toLowerCase().includes(value.toLowerCase()) || item.telephones.some((item) => item.num?.toLowerCase().includes(value.toLowerCase()))
  })
}


export const TableContacts = ({data, setFile}) => {
  const [contacts, setContacts] = useState([])
  const [importedContacts, setImportedContacts] = useState([])
  const [isShowModal, setIsShowModal] = useState(false)
  const [isShowModalProgress, setIsShowModalProgress] = useState(false)
  const [isShowModalError, setIsShowModalError] = useState(false)
  const [countImported, setCountImported] = useState(0)
  const [isCheckedAll, setIsCheckedAll] = useState(true)
  const [availableContacts, setAvailableContacts] = useState([])
  const [isDownloadContacts, setIsDownloadContacts] = useState(false)
  const [page, setPage] = useState(1)
  const {keycloak} = useKeycloak()
  const getContactsAll = () => {
    setIsShowModalError(false)
    getContacts(page, keycloak.token).then((response) => {
      setAvailableContacts(prevState => [...prevState, ...response.data.results])
      if (response.data.next) {
        setPage(prevState => prevState + 1)
      } else {
        setIsDownloadContacts(true)
      }
    }).catch(() => {
      setIsDownloadContacts(true)
      setIsShowModalError(true)

    })
  }

  useEffect(() => {
    getContactsAll()

  }, [page])

  useEffect(() => {
    const prepareData = data.map((item, index) => {
      item.id = index
      return item
    })

    setContacts(prepareData)
    setImportedContacts(prepareData.filter(item => {
          return checkValidContact(item, availableContacts) === 'ok'
        }
      )
    )
  }, [data, isDownloadContacts])

  useEffect(() => {
    if (contacts.length > 0) {
      if (importedContacts.length > 0 && importedContacts.length === contacts.filter(item => checkValidContact(item, availableContacts) === 'ok').length) {
        setIsCheckedAll(true)
      } else {
        setIsCheckedAll(false)
      }
    }

  }, [importedContacts, availableContacts])

  const onSubmit = async () => {
    setIsShowModal(false)
    setIsShowModalProgress(true)


    let size = 25
    let subarray = []
    for (let i = 0; i < Math.ceil(importedContacts.length / size); i++) {
      subarray[i] = importedContacts.slice((i * size), (i * size) + size)
    }
    for (const j of subarray) {
      const promises = j.map((item) => {

        const {photo} = item

        return createContact(createContactData(item), keycloak.token).then((response) => {
          setCountImported(prevState => prevState + 1)
          if (photo) {
            if (typeof photo === 'string') {
              let typeImage = photo.split(';')[0]
              typeImage = typeImage.includes('=') ? typeImage.split('=')[1] : typeImage.split('/')[1]

              fetch(photo)
                .then(response => response.blob())
                .then(blob => {
                  const bodyFormData = new FormData()
                  const file = new File([blob], `photo.${typeImage}`)
                  bodyFormData.append('photo', file)
                  createPhotoContact(response.data.uuid, bodyFormData, keycloak.token).then((r) => console.log(r))
                })
            } else {
              const bodyFormData = new FormData()
              bodyFormData.append('photo', photo[0])
              createPhotoContact(response.data.uuid, bodyFormData, keycloak.token).then((r) => console.log(r))
            }

          }
        })
      })
      await Promise.all(promises)
    }
  }

  return (
    <div className={'wrapper'}>
      {isShowModal &&
        <Modals count={importedContacts.length} submit={onSubmit} funcExit={setIsShowModal}
                setIsShowModalProgress={setIsShowModalProgress}
                progress={countImported}/>
      }
      {
        isShowModalProgress &&
        <ProgressBarModal progress={countImported} count={importedContacts.length}
                          funcExit={setIsShowModalProgress} setFile={setFile}/>
      }
      {
        !isDownloadContacts && <ModalParsing/>
      }
      {
        isShowModalError &&
        <ModalError setFile={setFile} page={page} getContactsAll={getContactsAll} setPage={setPage}
                    setIsDownloadContacts={setIsDownloadContacts}/>
      }

      <h2>Перенос контактов</h2>
      <div className={'text-field wrapper'}>
        <div className={'text-field__icon text-field__icon_search'}>
          <input className={'text-field__input'} type={'text'} placeholder={'Поиск контакта'}
                 onChange={(e) => setContacts(searchContacts(data, e.target.value))}/>
        </div>
      </div>

      <div className={'card wrapper'}>
        <table className={'rounded'}>
          <thead className={''}>
          <tr className={''}>
            <th className={'w-25'}>
              <div className={'checkbox my-2'}>
                <input type={'checkbox'} id={'all'} checked={isCheckedAll}
                       onChange={(event) => {
                         event.target.checked === true ? setImportedContacts(contacts.filter(item => checkValidContact(item, availableContacts) === 'ok')) : setImportedContacts([])
                       }}/>
                <label htmlFor={'all'} className={'checkbox_label'}> Все

                </label>
              </div>

            </th>
            <th style={{width: '20%'}}>Фото</th>
            <th style={{width: '30%'}}>Имя контакта</th>
            <th>Номер телефона</th>
            <th></th>
          </tr>

          </thead>
          <tbody>
          {isDownloadContacts &&
            contacts.map((item, index) => {
              // console.log(item)
              return <ContactRow key={item.id.toString()} currentIndex={index}
                                 importedContacts={importedContacts}
                                 setImportedContacts={setImportedContacts}
                                 availableContacts={availableContacts}
                                 countContactsAll={contacts.length}
                                 setContacts={setContacts}
                                 contact={item}/>
            })
          }
          </tbody>
        </table>
        <div className={'mt-3 d-flex justify-content-end'}>
          <button type={'button'} disabled={importedContacts.length < 1}
                  className={'btn btn-primary'}
                  onClick={() => setIsShowModal(true)}>Перенести
          </button>
        </div>

      </div>
    </div>

  )
}