import {useEffect, useState} from 'react'
import {checkPhoneNumber} from '../../functions/helpers'
import {iconClose} from '../../images/iconClose'

export const NumberField = ({
                              tel,
                              availableContacts,
                              setIsDisabledBtn,
                              index,
                              setNewTelephones,
  isCountTel
                            }) => {

  const [newPhoneNumber, setNewPhoneNumber] = useState(null)
  const [isUniqueNumber, setIsUniqueNumber] = useState(true)
  const [isValidNumber, setIsValidNumber] = useState(true)

  useEffect(() => {
    setIsDisabledBtn(true)
    if (availableContacts && newPhoneNumber) {
      setIsUniqueNumber(true)
      availableContacts.forEach((item) => {
        item.phone_numbers.some((_item) => {
          if (_item.phone_number === newPhoneNumber.num) {
            setIsUniqueNumber(false)
            setIsDisabledBtn(true)
          }
        })
      })
    }
    if (newPhoneNumber) {
      if (newPhoneNumber.num.length > 2 && newPhoneNumber.num.length < 20) {
        if (newPhoneNumber.num.length < 6) {
          setIsDisabledBtn(false)
          setIsValidNumber(true)
        } else {
          if ((/^\d+$/g.test(newPhoneNumber.num.slice(1)) && (newPhoneNumber.num[0] === '+' || newPhoneNumber.num[0] === '8'))) {
            setIsDisabledBtn(false)
            setIsValidNumber(true)
          }
        }

      } else {
        setIsDisabledBtn(true)
        setIsValidNumber(false)
      }
    }
  }, [newPhoneNumber])


  useEffect(() => {
    if (newPhoneNumber) {
      setNewTelephones((prevState) => {
        const abc = [...prevState]
        abc[index] = newPhoneNumber
        return abc
      })
    }
  }, [newPhoneNumber])

  useEffect(() => {
    if (tel) {
      setNewPhoneNumber(tel)
    }
  }, [tel])

  return (
    <>
      <label className={`text-left ${isCountTel ? 'mt-0' : 'mt-1'}`}>НОМЕР ТЕЛЕФОНА*</label>
      <div className={'d-flex position-relative'}>
        <input style={{width: '40%'}} className={'mr-2'}
               value={checkPhoneNumber(tel.type)} type="text" disabled/>
        <input value={newPhoneNumber?.num} type="text"
               onChange={(e) => setNewPhoneNumber({num: e.target.value, type: tel.type})}
               required/>
        <div>
          {index > 0 ?
            <span className={'btn-close'} onClick={() => setNewTelephones((prevState) => {
              return prevState.filter((item, _index) => _index !== index)
            })}>{iconClose}</span> : ''}
        </div>

      </div>
      <p className={'align-self-end warning-text'}>{isUniqueNumber
        ? isValidNumber
          ? ''
          : 'Неверный номер'
        : 'Этот номер уже существует'}</p>
    </>
  )
}