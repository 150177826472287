import {LogoEFKO} from '../../images/LogoEFKO'
import {useKeycloak} from '@react-keycloak/web'
import {useEffect, useState} from 'react'
import {iconUser} from '../../images/iconUserPhoto'
import {getAdminInfo} from '../../api/api'


export const Header = ({setIsPageWelcome}) => {
  const {keycloak} = useKeycloak()
  const [{given_name, family_name}, setName] = useState({})
  const [isShowExit, setIsShowExit] = useState(false)
  const [photoAdmin, setPhotoAdmin] = useState(null)

  useEffect(() => {
    keycloak.loadUserInfo().then(setName)
  }, [keycloak])

  const logOut = () => {
    keycloak.logout()
  }

  useEffect(() => {
    getAdminInfo(keycloak.token).then((response) => {
      setPhotoAdmin(response.data.account.profile_photo_thumbnail)
    })
  }, [])


  return (
    <header>
      <div className={'wrapper'}>
        <div className={'logo-efko'} onClick={() => setIsPageWelcome(true)}>
          <LogoEFKO width={90} style={{position: 'relative', top: '-5px'}}/>
        </div>

        <div className={'admin'}>
          <p onClick={() => setIsShowExit(!isShowExit)}>{given_name}</p>
          <div className={'admin-icon-user'} onClick={() => setIsShowExit(!isShowExit)}>{photoAdmin ?
            <img src={photoAdmin} alt={'photo'}/> : iconUser}
          </div>

          <div className={'admin-menu'} style={!isShowExit ? {display: 'none'} : {}}
               onClick={() => setIsShowExit(false)}>
            <div className={'rounded overflow-hidden'}>
              <img src={photoAdmin} alt={'photo'}/>
            </div>
            <p>{`${given_name} ${family_name}`}</p>
            <button className={'btn btn-outline-secondary'} onClick={logOut}>Выйти</button>
          </div>
        </div>
      </div>
    </header>
  )
}